import type { ComponentProps } from "react"

/**
 * Conditionally renders a wrapper component
 * @param if_ The condition to render the wrapper
 * @param with The wrapper component to render
 * @param className The className to apply to the wrapper
 * @param children The children to render
 * @param props The props to pass to the wrapper component
 */
export const ConditionalWrapper = ({
  // eslint-disable-next-line quote-props
  if: if_,
  // eslint-disable-next-line quote-props
  with: With = "div",
  className,
  children,
  props,
}: {
  if: boolean | undefined
  className?: string
  with?: React.ElementType
  children: React.ReactNode | React.ReactNode[]
  props?: ComponentProps<typeof With>
}) =>
  if_ ? (
    <With className={className} {...props}>
      {children}
    </With>
  ) : (
    <>{children}</>
  )
