import { getAddress, isAddress } from "viem"
import * as YupNative from "yup"

export type Schema = YupNative.Schema
export type YupInfer<T extends YupNative.ISchema<any, any>> =
  YupNative.InferType<T>
export type ObjectSchema = YupNative.ObjectSchema<any>
export type SchemaObjectDescription = YupNative.SchemaObjectDescription

// Usage: yup.string().isAddress("My Custom Message").required()
YupNative.addMethod(
  YupNative.string,
  "isAddress",
  function yupIsAddress(message = "is not valid") {
    return this.meta({
      props: {
        placeholder: "0x",
        ...this.describe().meta?.props,
      },
    })
      .test("is-address", message, (value) => {
        if (value && value !== "") {
          return isAddress(value)
        }
        return true
      })
      .transform((value) => {
        if (value && isAddress(value)) {
          return getAddress(value)
        }
        return value
      })
  },
)

// Usage: yup.number().nullify().
// useful when casting before submitting: const values = schema.cast(valuesIn)
// vs code shows an error, but there are none 😭
// @ts-ignore This seems perfectly fine, the nullable method is there, so hiding this ts error
YupNative.addMethod(YupNative.number, "nullify", function nullifyIfEmpty() {
  return this.transform((value) => {
    return value === "" || isNaN(value) ? null : value
  }).nullable()
})

// Usage: yup.number().nullify().
// useful when casting before submitting: const values = schema.cast(valuesIn)
// vs code shows an error, but there are none 😭
// @ts-ignore This seems perfectly fine, the nullable method is there, so hiding this ts error
YupNative.addMethod(YupNative.string, "nullify", function nullifyIfEmpty() {
  return this.transform((value) => {
    return value === "" ? null : value
  }).nullable()
})

// Usage: yup.date().nullify().
// useful when casting before submitting: const values = schema.cast(valuesIn)
// vs code shows an error, but there are none 😭
// @ts-ignore This seems perfectly fine, the nullable method is there, so hiding this ts error
YupNative.addMethod(YupNative.date, "nullify", function nullifyIfEmpty() {
  return this.nullable().transform((v: any) =>
    v === "" || v === null ? null : v,
  )
})

export default YupNative as typeof YupNative

// for some reason we can't add requiredIfFinal to MixedSchema only
// so we add to every primitive type's schemas... it's ok.
declare module "yup" {
  interface StringSchema {
    eth(): this
    minEth(min: number | bigint, message?: string | (() => string)): this
    maxEth(min: number | bigint, message?: string | (() => string)): this
    isAddress(message?: string | (() => string)): this
    nullify(): this
  }
  interface NumberSchema {
    nullify(): this
    isTwoDecimalMax(message?: string | (() => string)): this
  }
  interface DateSchema {
    nullify(): this
  }
}
