export const LoomlockLogoFull = (props) => (
  <svg
    width="327"
    height="119"
    viewBox="0 0 327 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_414_16)">
      <path
        d="M110.4 73.1099C109.17 73.1099 108.18 72.1199 108.18 70.8899V44.1899H103.28V71.8899C103.28 75.0399 105.83 77.5999 108.99 77.5999H111.91V73.1099H110.41H110.4Z"
        fill="currentColor"
      />
      <path
        d="M219.28 61.8099V76.9699H214.56V62.9899C214.56 60.8899 214.3 55.6399 208.9 55.4599C203.51 55.6399 203.24 60.8899 203.24 62.9899V76.9699H198.5V62.9899C198.5 60.8999 198.24 55.6799 192.9 55.4699C187.56 55.6799 187.3 60.8999 187.3 62.9899V76.9699H182.58V61.8099C182.58 59.3599 183.08 57.3199 183.99 55.6799C185.62 52.8599 188.62 51.1399 192.84 51.1399H192.97C196.47 51.1399 199.14 52.3299 200.87 54.3499C202.61 52.3299 205.27 51.1399 208.78 51.1399H209.02C213.24 51.1399 216.24 52.8699 217.87 55.6799C218.78 57.3099 219.28 59.3599 219.28 61.8099Z"
        fill="currentColor"
      />
      <path
        d="M131.12 51.1399C123.31 51.1399 117.55 57.3599 117.55 64.2099C117.55 70.5599 122.68 77.5999 131.12 77.5999C139.56 77.5999 144.69 70.4299 144.69 64.2999C144.69 57.1299 138.74 51.1399 131.12 51.1399ZM131.12 73.2899C125.86 73.2899 122.27 68.8899 122.27 64.3499C122.27 59.8099 125.81 55.4599 131.12 55.4599C136.43 55.4599 139.97 59.8599 139.97 64.3099C139.97 69.0799 136.2 73.2999 131.12 73.2999V73.2899Z"
        fill="currentColor"
      />
      <path
        d="M162.98 51.1399C155.17 51.1399 149.41 57.3599 149.41 64.2099C149.41 70.5599 154.54 77.5999 162.98 77.5999C171.42 77.5999 176.55 70.4299 176.55 64.2999C176.55 57.1299 170.6 51.1399 162.98 51.1399ZM162.98 73.2899C157.72 73.2899 154.13 68.8899 154.13 64.3499C154.13 59.8099 157.67 55.4599 162.98 55.4599C168.29 55.4599 171.83 59.8599 171.83 64.3099C171.83 69.0799 168.06 73.2999 162.98 73.2999V73.2899Z"
        fill="currentColor"
      />
      <path
        d="M254.66 51.1399C246.85 51.1399 241.09 57.3599 241.09 64.2099C241.09 70.5599 246.22 77.5999 254.66 77.5999C263.1 77.5999 268.23 70.4299 268.23 64.2999C268.23 57.1299 262.28 51.1399 254.66 51.1399ZM254.66 73.2899C249.4 73.2899 245.81 68.8899 245.81 64.3499C245.81 59.8099 249.35 55.4599 254.66 55.4599C259.97 55.4599 263.51 59.8599 263.51 64.3099C263.51 69.0799 259.74 73.2999 254.66 73.2999V73.2899Z"
        fill="currentColor"
      />
      <path
        d="M285.76 73.2899C280.36 73.2899 277.59 68.6999 277.59 64.3499C277.59 60.5399 279.77 55.4599 285.85 55.4599C290.27 55.4599 292.1 58.4899 292.89 60.0999L297.07 57.6099C295.21 54.4099 291.39 51.1499 285.81 51.1499C277.87 51.1499 272.88 57.5499 272.88 64.3599C272.88 71.1699 277.51 77.6099 285.77 77.6099C290.47 77.6099 294.68 75.2299 297.04 71.1499L292.89 68.6699C291.71 71.2499 289.31 73.2899 285.77 73.2899H285.76Z"
        fill="currentColor"
      />
      <path
        d="M234.67 73.1099C233.44 73.1099 232.45 72.1199 232.45 70.8899V44.1899H227.55V71.8899C227.55 75.0399 230.1 77.5999 233.26 77.5999H236.18V73.1099H234.68H234.67Z"
        fill="currentColor"
      />
      <path d="M303.25 44.2V77.61H307.97V44.2H303.25Z" fill="currentColor" />
      <path
        d="M326.23 77.6001H319.84L307.97 64.5601L320.86 52.8201L323.94 56.2001L314.41 64.8701L326.23 77.6001Z"
        fill="currentColor"
      />
      <path
        d="M33.0701 101.1C33.2801 101.08 33.4901 101.02 33.6901 100.93C33.4901 101.01 33.2801 101.07 33.0701 101.1ZM32.4401 101.1C32.5501 101.12 32.6501 101.12 32.7601 101.12C32.8701 101.12 32.9701 101.12 33.0701 101.1C32.8601 101.13 32.6501 101.13 32.4401 101.1ZM32.4401 101.1C32.2301 101.07 32.0201 101.02 31.8201 100.93C32.0201 101.01 32.2301 101.07 32.4401 101.1Z"
        fill="currentColor"
      />
      <path
        d="M49.13 87.81L35.21 79.77V46.2C35.21 45.32 34.74 44.51 33.98 44.08L18.83 35.33C18.08 34.89 17.14 34.89 16.38 35.33L1.23 44.07C0.48 44.51 0 45.31 0 46.19V98.68C0 99.55 0.47 100.36 1.23 100.8L31.53 118.3C31.91 118.52 32.33 118.63 32.76 118.63C33.19 118.63 33.6 118.52 33.98 118.3L49.13 109.55C49.89 109.11 50.36 108.31 50.36 107.43V89.93C50.36 89.06 49.89 88.25 49.13 87.81ZM17.6 40.27L30.3 47.61V81.19C30.3 82.06 30.77 82.87 31.52 83.31L43 89.94L32.74 95.87L20.04 88.53V54.95C20.04 54.08 19.57 53.27 18.81 52.83L7.33 46.2L17.59 40.27H17.6ZM45.46 106.02L32.76 113.35L4.9 97.27V50.43L15.16 56.36V89.93C15.16 90.81 15.63 91.62 16.38 92.05L31.53 100.8C31.62 100.85 31.72 100.9 31.82 100.94C32.02 101.02 32.23 101.08 32.44 101.11C32.65 101.14 32.86 101.14 33.07 101.11C33.28 101.08 33.49 101.03 33.69 100.94C33.79 100.9 33.88 100.85 33.98 100.8L45.46 94.17V106.02Z"
        fill="currentColor"
      />
      <path
        d="M79.4301 17.83L49.1301 0.33C48.3801 -0.11 47.4401 -0.11 46.6801 0.33L31.5301 9.08C30.7801 9.52 30.3101 10.32 30.3101 11.2V28.7C30.3101 29.57 30.7801 30.38 31.5301 30.82L45.4601 38.86V72.44C45.4601 73.31 45.9301 74.12 46.6801 74.56L61.8301 83.31C62.2101 83.53 62.6301 83.64 63.0601 83.64C63.4901 83.64 63.9001 83.53 64.2801 83.31L79.4301 74.56C80.1901 74.12 80.6601 73.32 80.6601 72.44V19.95C80.6601 19.07 80.1901 18.26 79.4301 17.83ZM49.1301 35.32L35.2101 27.28V15.44L60.6201 30.11V76.95L50.3601 71.03V37.45C50.3601 36.58 49.8901 35.77 49.1301 35.33V35.32ZM75.7601 71.02L65.5001 76.94V28.69C65.5001 27.82 65.0301 27.01 64.2701 26.57L37.6401 11.2L47.9001 5.28L75.7501 21.36V71.02H75.7601Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_414_16">
        <rect width="326.23" height="118.63" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const LoomlockName = (props) => (
  <svg
    width="334"
    height="50"
    viewBox="0 0 334 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.6576 43.2724C8.82326 43.2724 7.33753 41.7827 7.33753 39.9523V0H0V41.4618C0 46.1805 3.82328 49.9998 8.538 49.9998H12.9041V43.2724H10.6576Z"
      fill="currentColor"
    />
    <path
      d="M173.62 26.3669V49.0531H166.556V28.134C166.556 24.9921 166.164 17.1356 158.093 16.8702C150.023 17.1356 149.631 24.9921 149.631 28.134V49.0531H142.539V28.134C142.539 25.0001 142.147 17.1911 134.159 16.8741C126.168 17.1911 125.78 25.0001 125.78 28.134V49.0531H118.716V26.3669C118.716 22.6982 119.464 19.6396 120.819 17.199C123.264 12.9835 127.753 10.3963 134.064 10.3963H134.254C139.5 10.3963 143.486 12.1832 146.085 15.2061C148.688 12.1832 152.673 10.3963 157.919 10.3963H158.276C164.587 10.3963 169.076 12.9795 171.52 17.199C172.879 19.6435 173.624 22.7021 173.624 26.3669H173.62Z"
      fill="currentColor"
    />
    <path
      d="M41.6678 10.4003C29.984 10.4003 21.3589 19.7069 21.3589 29.9643C21.3589 39.473 29.0332 49.9999 41.6678 49.9999C54.3025 49.9999 61.9768 39.267 61.9768 30.0991C61.9768 19.3701 53.0782 10.4003 41.6678 10.4003ZM41.6678 43.5499C33.7915 43.5499 28.423 36.9611 28.423 30.1664C28.423 23.3717 33.7241 16.8543 41.6678 16.8543C49.6115 16.8543 54.9126 23.443 54.9126 30.0951C54.9126 37.2306 49.2748 43.5459 41.6678 43.5459V43.5499Z"
      fill="currentColor"
    />
    <path
      d="M89.3618 10.4003C77.678 10.4003 69.0529 19.7069 69.0529 29.9643C69.0529 39.473 76.7271 49.9999 89.3618 49.9999C101.996 49.9999 109.671 39.267 109.671 30.0991C109.671 19.3701 100.772 10.4003 89.3618 10.4003ZM89.3618 43.5499C81.4854 43.5499 76.121 36.9611 76.121 30.1664C76.121 23.3717 81.4181 16.8543 89.3618 16.8543C97.3055 16.8543 102.607 23.443 102.607 30.0951C102.607 37.2306 96.9727 43.5459 89.3618 43.5459V43.5499Z"
      fill="currentColor"
    />
    <path
      d="M226.572 10.4003C214.888 10.4003 206.263 19.7069 206.263 29.9643C206.263 39.473 213.937 49.9999 226.572 49.9999C239.207 49.9999 246.881 39.267 246.881 30.0991C246.881 19.3701 237.982 10.4003 226.572 10.4003ZM226.572 43.5499C218.696 43.5499 213.327 36.9611 213.327 30.1664C213.327 23.3717 218.628 16.8543 226.572 16.8543C234.516 16.8543 239.817 23.443 239.817 30.0951C239.817 37.2306 234.179 43.5459 226.572 43.5459V43.5499Z"
      fill="currentColor"
    />
    <path
      d="M273.109 43.5499C265.027 43.5499 260.886 36.6878 260.886 30.1664C260.886 24.4612 264.147 16.8543 273.248 16.8543C279.868 16.8543 282.602 21.3947 283.79 23.8035L290.038 20.0714C287.261 15.2814 281.532 10.4003 273.18 10.4003C261.291 10.4003 253.822 19.9763 253.822 30.1664C253.822 40.3565 260.752 49.9999 273.113 49.9999C280.145 49.9999 286.453 46.4421 289.979 40.3328L283.775 36.6283C282.008 40.4952 278.41 43.5499 273.113 43.5499H273.109Z"
      fill="currentColor"
    />
    <path
      d="M196.643 43.2724C194.809 43.2724 193.323 41.7827 193.323 39.9523V0H185.986V41.4618C185.986 46.1805 189.809 49.9998 194.524 49.9998H198.89V43.2724H196.643Z"
      fill="currentColor"
    />
    <path d="M299.289 0V49.9958H306.35V0H299.289Z" fill="currentColor" />
    <path
      d="M333.675 50H324.123L306.35 30.4756L325.641 12.9043L330.244 17.9677L315.985 30.9431L333.675 50Z"
      fill="currentColor"
    />
  </svg>
)

export const LoomlockLogo = (props) => (
  <svg
    width="81"
    height="119"
    viewBox="0 0 81 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.0701 101.1C33.2801 101.08 33.4901 101.02 33.6901 100.93C33.4901 101.01 33.2801 101.07 33.0701 101.1ZM32.4401 101.1C32.5501 101.12 32.6501 101.12 32.7601 101.12C32.8701 101.12 32.9701 101.12 33.0701 101.1C32.8601 101.13 32.6501 101.13 32.4401 101.1ZM32.4401 101.1C32.2301 101.07 32.0201 101.02 31.8201 100.93C32.0201 101.01 32.2301 101.07 32.4401 101.1Z"
      fill="currentColor"
    />
    <path
      d="M49.13 87.81L35.21 79.77V46.2C35.21 45.32 34.74 44.51 33.98 44.08L18.83 35.33C18.08 34.89 17.14 34.89 16.38 35.33L1.23 44.07C0.48 44.51 0 45.31 0 46.19V98.68C0 99.55 0.47 100.36 1.23 100.8L31.53 118.3C31.91 118.52 32.33 118.63 32.76 118.63C33.19 118.63 33.6 118.52 33.98 118.3L49.13 109.55C49.89 109.11 50.36 108.31 50.36 107.43V89.93C50.36 89.06 49.89 88.25 49.13 87.81ZM17.6 40.27L30.3 47.61V81.19C30.3 82.06 30.77 82.87 31.52 83.31L43 89.94L32.74 95.87L20.04 88.53V54.95C20.04 54.08 19.57 53.27 18.81 52.83L7.33 46.2L17.59 40.27H17.6ZM45.46 106.02L32.76 113.35L4.9 97.27V50.43L15.16 56.36V89.93C15.16 90.81 15.63 91.62 16.38 92.05L31.53 100.8C31.62 100.85 31.72 100.9 31.82 100.94C32.02 101.02 32.23 101.08 32.44 101.11C32.65 101.14 32.86 101.14 33.07 101.11C33.28 101.08 33.49 101.03 33.69 100.94C33.79 100.9 33.88 100.85 33.98 100.8L45.46 94.17V106.02Z"
      fill="currentColor"
    />
    <path
      d="M79.4301 17.83L49.1301 0.33C48.3801 -0.11 47.4401 -0.11 46.6801 0.33L31.5301 9.08C30.7801 9.52 30.3101 10.32 30.3101 11.2V28.7C30.3101 29.57 30.7801 30.38 31.5301 30.82L45.4601 38.86V72.44C45.4601 73.31 45.9301 74.12 46.6801 74.56L61.8301 83.31C62.2101 83.53 62.6301 83.64 63.0601 83.64C63.4901 83.64 63.9001 83.53 64.2801 83.31L79.4301 74.56C80.1901 74.12 80.6601 73.32 80.6601 72.44V19.95C80.6601 19.07 80.1901 18.26 79.4301 17.83ZM49.1301 35.32L35.2101 27.28V15.44L60.6201 30.11V76.95L50.3601 71.03V37.45C50.3601 36.58 49.8901 35.77 49.1301 35.33V35.32ZM75.7601 71.02L65.5001 76.94V28.69C65.5001 27.82 65.0301 27.01 64.2701 26.57L37.6401 11.2L47.9001 5.28L75.7501 21.36V71.02H75.7601Z"
      fill="currentColor"
    />
  </svg>
)
