import React from "react"
import { cn } from "../lib"

interface FormSectionProps {
  title: string
  children: React.ReactNode
  unstyledChildren?: boolean
}

export const FormSection: React.FC<FormSectionProps> = ({
  title,
  children,
  unstyledChildren = false,
}) => {
  return (
    <div className="pt-10 grid grid-cols-1 md:grid-cols-[15ch,auto] lg:grid-cols-[20ch,auto] xl:grid-cols-[40ch,auto] gap-x-6 first:border-t-0 border-t border-gray-200 bg-gray-100">
      <h3 className="text-lg font-medium leading-6 pt-8">{title}</h3>
      <div
        className={cn(
          !unstyledChildren &&
            "border border-gray-200 bg-white rounded-lg p-8 flex flex-col gap-4",
        )}
      >
        {children}
      </div>
    </div>
  )
}
