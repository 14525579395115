import { cn } from "@abraxas/ui/lib"

export const PageTitle = ({
  title,
  subtitle,
  className,
  size = "base",
}: {
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  className?: string
  size?: "base" | "large"
}) => {
  return (
    <div className={cn("flex flex-col gap-4 mb-12 text-white", className)}>
      <h1
        className={cn(
          "font-semibold",
          size === "large" && "text-5xl md:text-subtitle-2 lg:text-title-2",
          size === "base" && "text-4xl md:text-4xl lg:text-title",
        )}
      >
        {title}
      </h1>
      {subtitle && <div>{subtitle}</div>}
    </div>
  )
}
