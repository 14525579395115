"use client"
import { cn } from "@abraxas/ui/lib"
import { Button } from "@abraxas/ui/ui"
import { PageTitle } from "./PageTitle"

export const TelegramFooter = ({ className }: { className?: string }) => (
  <div
    className={cn(
      "text-center bg-cover pb-128 bg-bottom bg-no-repeat",
      "translate-y-[1px]",
      className,
    )}
  >
    <div className="md:flex-row  flex gap-8 items-center justify-center">
      <div className="self-stretch">
        <img
          src="https://lock.nyc3.cdn.digitaloceanspaces.com/assets/lockgram.webp"
          alt="decorative"
          className="aspect-square cover  md:w-40 w-36"
        />
      </div>
      <PageTitle
        size="large"
        className="mb-0 text-left"
        title={
          <>
            <span className="text-white">be the first</span>
            <br className="hidden md:block" /> to know!
          </>
        }
      />
    </div>
    <div className="uppercase font-bold text-button mt-8">loomlock Updates</div>
    <p className=" md:mb-4">
      Join our Telegram Channel to receive project updates, exclusive offers,
      and more.
    </p>

    <div className="absolute left-0 w-full text-center pt-4">
      <Button href="https://t.me/loomlockupdates" size="large">
        loomlock updates
      </Button>
    </div>
    <div
      className="md:h-auto h-48 bg-center bg-cover"
      style={{
        backgroundImage: `url("https://lock.nyc3.cdn.digitaloceanspaces.com/assets/footer-lg.webp")`,
      }}
    >
      <img
        src="https://lock.nyc3.cdn.digitaloceanspaces.com/assets/footer-lg.webp"
        className="w-full h-auto md:mt-12 mt-16 hidden md:block"
        alt="decoration"
      />
    </div>
  </div>
)
